<template>
  <CollectionSlider
    class="paragraph"
    :title="$texts('homeCollectionSlider', 'Our Collection')"
  />
</template>

<script lang="ts" setup>
const { $texts } = useEasyTexts()

defineBlokkliFragment({
  name: 'collection_slider',
  label: 'Collection Slider',
  description: 'Renders the promoted collections as a slider.',
  editor: {
    maxInstances: 1,
    previewWidth: 1400,
  },
})
</script>
