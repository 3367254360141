<template>
  <div class="paragraph relative" :class="{ 'min-h-112': isEditing }">
    <BlokkliField
      name="field_paragraphs"
      :list="paragraphs"
      edit-only
      list-class="!absolute bottom-0 left-0 w-full z-50 inline-flex gap-24 mx-auto justify-center px-64"
    >
      <template #after="{ items }">
        <ParagraphSwitcherRenderer
          :key="items.length"
          :items="items"
          :overline="overline"
        />
      </template>
    </BlokkliField>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphSwitcherFragment } from '#build/graphql-operations'

const { isEditing } = defineBlokkli({
  bundle: 'switcher',
})

defineProps<{
  overline?: string
  paragraphs?: ParagraphSwitcherFragment['paragraphs']
}>()
</script>
