<template>
  <div class="flex justify-center">
    <NuxtLink
      :to="to"
      class="cursor-pointer pt-40 uppercase underline underline-offset-8 mobile-only:pt-16"
    >
      {{ $texts('productFinder.loadMore', 'Load More') }}
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
const { $texts } = useEasyTexts()

defineProps<{
  to?: string
}>()
</script>
