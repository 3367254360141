<template>
  <div v-if="collections.length" class="collection-slider layout-grid">
    <DynamicFontSizeHeading
      class="col-span-full col-start-2 mb-16 uppercase md:col-span-5 md:col-start-3 md:mb-40 xxxl:col-span-10 xxxl:col-start-2"
    >
      {{ title }}
    </DynamicFontSizeHeading>
    <div
      class="col-span-full col-start-2 md:col-start-3 md:col-end-[-1] xxxl:col-start-2"
    >
      <ProductVariationSlider :items="collections">
        <template #item="collection">
          <CollectionTeaser
            v-bind="collection"
            class="w-[289px] md:w-[416px]"
          />
        </template>
      </ProductVariationSlider>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { collections } = await useWatchCollectionData()
defineProps<{ title: string }>()
</script>

<style lang="scss" scoped>
.collection-slider > * {
  @media (screen(xxxl)) {
    margin-left: -154px;
    max-width: calc(100% + 154px);
  }
}
</style>
