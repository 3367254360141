<template>
  <div class="paragraph mx-auto max-w-screen-xxxl">
    <h2
      class="mb-40 text-5xl/[56px] font-light uppercase mobile-only:px-16 md:mb-72 md:ml-[92px] md:text-[80px] md:leading-[88px]"
    >
      {{ $texts('movementDetails.technicalDetails', 'Technical details') }}
    </h2>

    <div class="grid grid-cols-4 md:grid-cols-12">
      <div class="col-span-3 col-start-2 md:col-span-9 md:col-start-4">
        <MediaImage
          v-if="caliber?.image"
          v-bind="caliber?.image"
          :image-style="imageStyle"
          class="mb-30 md:mb-80"
          img-class="w-full"
        />
      </div>
      <div
        class="col-span-4 row-start-2 border-grey-light-01 mobile-only:mx-16 mobile-only:border-t mobile-only:pt-[22px] md:col-span-3 md:col-start-4 md:row-start-2"
      >
        <h3
          class="mb-16 text-[20px] font-light uppercase leading-[32px] md:text-xl/[40px]"
        >
          {{ $texts('movementDetails.movement', 'Movement') }}
        </h3>
      </div>
      <div
        class="col-span-4 row-start-3 mobile-only:mx-16 md:col-span-4 md:col-start-7 md:row-start-2"
      >
        <div
          class="mb-[52px] text-base/[24px] font-light text-grey-dark-02 md:mb-64"
        >
          {{ caliber?.movementFunctions }}
        </div>
        <div v-if="caliber?.powerReserve" class="mb-36 md:mb-64">
          <div class="text-3xl font-light uppercase md:text-6xl">
            {{ caliber?.powerReserve }}
          </div>
          <div class="text-md font-light text-black">
            {{ $texts('movementDetails.powerReserve', 'Power reserve') }}
          </div>
        </div>
        <ul v-if="details.length" class="flex flex-col gap-32">
          <li v-for="(detail, index) in details" :key="index">
            <div
              class="mb-8 text-base/[24px] font-light uppercase text-grey-dark-02"
            >
              {{ detail.label }}
            </div>
            <div
              class="rich-text text-md font-light text-black"
              v-html="detail.value"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphMovementDetailsFragment } from '#graphql-operations'
import { falsy } from '~/helpers/type'

defineBlokkli({
  bundle: 'movement_details',
})

const props = defineProps<{
  caliber?: ParagraphMovementDetailsFragment['caliber']
}>()

type MovementDetails = {
  label: string
  value: string
}

const imageStyle = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      width: 300,
      aspectRatio: 4 / 3,
    },
    md: {
      width: 520,
      aspectRatio: 2 / 1,
    },
    lg: {
      width: 706,
      aspectRatio: 2 / 1,
    },
    xl: {
      width: 892,
      aspectRatio: 2 / 1,
    },
    xxl: {
      width: 1012,
      aspectRatio: 3 / 1,
    },
    xxxl: {
      width: 1012,
      aspectRatio: 5 / 2,
    },
  },
})

const { $texts } = useNuxtApp()

const details = computed<MovementDetails[]>(() => {
  if (!props.caliber) {
    return []
  }

  const features: (MovementDetails | undefined | null)[] = []

  if (props.caliber.number) {
    features.push({
      label: $texts('movementDetails.number', 'Number'),
      value: props.caliber.number,
    })
  }

  if (props.caliber.dimensions) {
    features.push({
      label: $texts('movementDetails.dimensions', 'Dimensions'),
      value: props.caliber.dimensions,
    })
  }

  if (props.caliber.winding) {
    features.push({
      label: $texts('movementDetails.winding', 'Winding'),
      value: props.caliber.winding,
    })
  }

  if (props.caliber.vibrations) {
    features.push({
      label: $texts('movementDetails.vibrations', 'Vibrations'),
      value: props.caliber.vibrations,
    })
  }

  return features.filter(falsy)
})
</script>
