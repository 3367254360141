<template>
  <section ref="rootEl" class="paragraph overflow-hidden">
    <div class="layout-grid">
      <div class="col-start-2 col-end-[-2] text-center">
        <p
          v-blokkli-editable:field_overline
          class="typo-overline-large mb-16 uppercase text-grey-dark-01"
        >
          {{ overline }}
        </p>
        <Text
          v-blokkli-editable:field_headline
          class="typo-headline-1 uppercase"
        >
          {{ headline }}
        </Text>
      </div>
      <div
        class="col-span-4 col-start-2 mt-16 text-center md:col-span-4 md:col-start-6"
      >
        <div
          v-blokkli-editable:field_description="{ type: 'frame' }"
          class="rich-text"
          v-html="text"
        />

        <DrupalLink
          v-if="to"
          :to="to"
          class="link-button mt-32 inline-block uppercase"
          >{{ linkText }}</DrupalLink
        >
      </div>
    </div>
    <div
      ref="carouselEl"
      class="carousel-images mt-56 flex items-end gap-16 will-change-transform md:mt-72 md:gap-24"
      :style="{ transform: `translateX(-${offset}px)` }"
    >
      <ParagraphCarouselItem
        v-for="(item, idx) in items"
        :key="idx"
        :media="item.media"
        :modulo-index="item.moduloIndex"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useElementVisibility } from '@vueuse/core'
import type {
  MediaImageFragment,
  ParagraphCarouselFragment,
} from '#graphql-operations'
import { falsy } from '~/helpers/type'

const { isEditing } = defineBlokkli({
  bundle: 'carousel',
})

const { $texts } = useNuxtApp()
const { isMobile } = useViewport()

const props = defineProps<{
  overline?: ParagraphCarouselFragment['overline']
  headline?: ParagraphCarouselFragment['headline']
  text?: ParagraphCarouselFragment['text']
  link?: ParagraphCarouselFragment['link']
  images?: ParagraphCarouselFragment['images']
}>()

const to = computed(() => props.link?.uri?.path)
const linkText = computed(
  () => props.link?.title || $texts('readMore', 'Read more'),
)

const rootEl = ref<HTMLElement | null>(null)
const carouselEl = ref<HTMLElement | null>(null)

const items = computed(() => {
  const media = (props.images || []).filter(falsy)
  const renderedItems: MediaImageFragment[] = []

  // We need exactly 15 items. If there are less, we will just start from the
  // beginning again. There can also be a maximum of 15 items (checked by Drupal).
  for (let i = 0; i < 15; i++) {
    renderedItems.push(media[i % media.length])
  }

  const mapped = renderedItems.map((v, i) => {
    // The modulo index decides the aspect ratio and size of each size. There
    // are 3 options, so we repeat just repeat them.
    const moduloIndex = i % 3
    return {
      media: v,
      moduloIndex,
    }
  })

  // We have to render each image twice so that we can do the "fake" infinite scroller.
  return [...mapped, ...mapped]
})

// Track visibility of element to skip animating the carousel.
const isVisible = useElementVisibility(rootEl)

const offset = ref(0)

useAnimationLoop((elapsed) => {
  if (!carouselEl.value || isEditing || !isVisible.value) {
    return
  }

  // Different speed on mobile because less space is available.
  const speed = isMobile.value ? 0.05 : 0.07

  // Use the elapsed time to calculate the offset.
  // This makes sure the animation runs smoothly.
  // We use the modulo operator on half the scroll width of the carousel
  // items so that when the offset reaches this value we reset it to 0.
  // That way we can fake an infinite slider.
  offset.value = (elapsed * speed) % (carouselEl.value.scrollWidth / 2)
})
</script>
